<template>
    <div>
        <van-nav-bar >
            <div slot="title" style="color:#13b5b1" @click="show = true">{{klineType}}</div>
        </van-nav-bar>
        <van-popup v-model="show"
                   position="bottom" round safe-area-inset-bottom	
                   :style="{ height: '40%' }">

            <div class="lang">
                <div v-for="(key,index) in chartTypes" :key="index">
                    <div @click="setKlineType(key)">{{key.text}}</div>
                </div>

            </div>
        </van-popup>
    </div>
</template>

<script>

    export default {
        name: "KLineTypeBar",
        data() {
            return {
                show: false,
                klineType: this.$t('KLineChart.candleSolid'),
                chartTypes: [
                    { id: 'candle_solid', text: this.$t('KLineChart.candleSolid') },
                    { id: 'candle_stroke', text: this.$t('KLineChart.candleSStroke') },
                    { id: 'candle_up_stroke', text: this.$t('KLineChart.candleUpStroke') },
                    { id: 'candle_down_stroke', text: this.$t('KLineChart.candleDownStroke') },
                    { id: 'ohlc', text: this.$t('KLineChart.candleOhlc') },
                    { id: 'area', text: this.$t('KLineChart.candleArea') }
                ]
            }
        },
        mounted() {
            let typeId = this.$store.state.klineType;            
            this.chartTypes.forEach(ex => {
                if (ex.id == typeId) {
                    this.klineType = ex.text;
                }
            })
        },
        methods: {
            setKlineType(ts) {
                this.$emit('listenKLineType', ts.id);
                this.klineType = ts.text;
                this.show = false;

            }
        }
    }
</script>

<style scoped>

    .lang {
        margin-top: 20px;
    }

        .lang > div {
            padding: 10px;
        }
</style>
