<template>
    <div class="chart">
        <van-sticky :z-index="999" :offset-top="0.1">
            <van-nav-bar class="klineBar">
                <slot slot="left" name="left">
                    <KLineTimeBar @listenKLineTime="klineTimeChange" />
                </slot>
                <slot slot="title" name="title">
                    <div @click="chooseSymbol" style="color:#fff">{{symbol}}</div>
                    
                </slot>
                <slot slot="right" name="right">
                    <!--<KLineTypeBar @listenKLineType="klineTypeChange" />-->
                    <div @click="toTrade" style="color:#fff">{{$t('Mt4ApiMenu.trade')}}</div>
                </slot>
            </van-nav-bar>
        </van-sticky>

        <van-col span="24" class="chartKline">
            <!--<van-row type="flex" justify="space-around" class="showTrade">
                <van-col span="8">
                    <div class="sell"  @click="openOrder(1)" :style="{'background-color':colorBid}">
                        SELL<span class="price">{{priceBid}}</span>
                    </div>
                </van-col>
                <van-col span="8">
                    <div class="volume">
                        <van-stepper v-model="volume"
                                     min="0.01" max="200" step="0.01" decimal-length="2"
                                     button-size="20" input-width="80px" />
                    </div>
                </van-col>
                <van-col span="8">
                    <div class="buy"  @click="openOrder(0)" :style="{'background-color':colorAsk}">
                        BUY<span class="price">{{priceAsk}}</span>
                    </div>
                </van-col>
            </van-row>-->
            <div class="k-line-chart-container">
                <!--<h3 class="k-line-chart-title" @click="chooseSymbol">{{symbol}}</h3>-->
                <div id="update-k-line" class="k-line-chart" />
            </div>
        </van-col>

    </div>
</template>
<script>
    import { dispose, init } from 'klinecharts'
    import { Notify, Dialog } from 'vant';
    import { GetKLineData, NewOrder, GetLastPrice } from "@/api/mt4Api";
    import { sendWebsocket, closeWebsocket } from '@/utils/websocket'
    import { floatAdd, floatSub, floatMul, forPrice } from "@/utils/commons";
    import KLineTimeBar from "@/components/mt4/KLineTimeBar";
    import KLineTypeBar from "@/components/mt4/KLineTypeBar";
    import baseConfig from '@/utils/baseConfig'
    export default {
        components: { KLineTimeBar, KLineTypeBar },
        data() {
            return {
                symbol: "XAUUSD",
                digits: 2,
                userinfo: this.$store.state.mt4userinfo,
                timeOutKline: 1000,//10 s
                kLineChart: null,
                timeZone: baseConfig.timeZone,
                theme: baseConfig.kLineTheme,
                period: 1,//minute
                space: 600 * 60,
                timeoutObj: null,
                klineTime: this.$store.state.klineTime,
                klineType: this.$store.state.klineType,
                volume: 0.01,
                priceAsk: '',
                colorAsk:'#13b5b1',
                priceBid: '',
                colorBid: '#13b5b1',
                openOrderDisabled:false,
            }
        },
        watch: {},
        mounted() {
            this.$store.commit('toggleTabbarShow', true);
            this.$store.commit('toggleTabbarCurrentShow', "Mt4");
            let s = this.$route.query.symbol;
            let d = this.$route.query.digits;
            if (s == undefined || d == undefined) {
                let sys = localStorage.getItem('chartSymbol');
                if (sys != null) {
                    let cache = JSON.parse(sys);
                    this.symbol = cache.symbol;
                    this.digits = cache.digits;
                }
            } else {
                this.symbol = s;
                this.digits = d;
                let cache = { "symbol": s, "digits": d };
                localStorage.setItem('chartSymbol', JSON.stringify(cache));
            }
            //this.lastPrice();
            //this.requestWs();

            this.loadKlineSettings();
            this.klineTimeChange(this.klineTime);
        },
        created() { },
        beforeCreate() { },
        beforeDestroy() {
            this.distoryTimer();
        },
        beforeRouteLeave(to, from, next) {
            if (!to.path.toLowerCase().indexOf('symbols')) {
                this.distoryTimer();
            }
            next()
        },
        methods: {
            loadKlineSettings() {
                let cache = localStorage.getItem('klineChart');
                if (cache != null) {
                    let json = JSON.parse(cache);
                    this.klineType = json.type;
                    this.theme = json.theme ;

                    //this.defaultKline.type = json.type;
                    //this.defaultKline.theme = json.theme;
                }
            },
            initChart() {
                let param = {
                    symbol: this.symbol,
                    period: this.period,
                    space: this.space
                };
                GetKLineData(param).then(res => {
                    if (res.code !== 200) {
                        console.log(res.msg);
                    } else {
                        console.log(res.msg);
                        this.kLineChart.clearData();
                        this.kLineChart.setPriceVolumePrecision(parseInt(this.digits), 0);
                        this.kLineChart.applyNewData(res.msg);
                    }
                })

                const updateData = () => {
                    param = {
                        symbol: this.symbol, period: 1, space: 1
                    }
                    this.timeoutObj = setTimeout(() => {
                        if (this.timeOutKline > 0) {
                            GetKLineData(param).then(res => {
                                if (res.code !== 200) {
                                    console.log(res.msg);
                                } else {
                                   // console.log(res.msg);
                                    this.kLineChart.updateData(res.msg[0]);
                                    updateData(this.kLineChart);
                                }
                            });
                        }
                    }, this.timeOutKline);
                    //console.log(this.timeoutObj);
                }

                this.kLineChart.setTimezone(this.timeZone);
                this.kLineChart.setStyleOptions(this.setStylesOptions(this.theme));

                updateData();
            },

            klineTimeChange(msg) {
                clearTimeout(this.timeoutObj);
                this.$store.commit('setKLineTime', msg);
                this.klineTime = msg;
                switch (msg) {
                    case "M1": this.period = 1; this.space = 600 * 60; break;
                    case "M5": this.period = 5; this.space = 600 * 60 * 5; break;
                    case "M15": this.period = 15; this.space = 600 * 60 * 15; break;
                    case "M30": this.period = 30; this.space = 600 * 60 * 30; break;
                    case "H1": this.period = 60; this.space = 600 * 60 * 60; break;
                    case "H4": this.period = 60 * 4; this.space = 600 * 60 * 60 * 4; break;
                    case "D1": this.period = 24 * 60; this.space = 600 * 60 * 24 * 60; break;
                    case "W1": this.period = 24 * 60 * 7; this.space = 600 * 60 * 24 * 60 * 7; break;
                    //case "MN": period = 1; space = 600 * 60; break;
                    default:
                }
                if (this.kLineChart == null) {
                    this.kLineChart = init('update-k-line');
                }
                var h = document.documentElement.clientHeight || document.body.clientHeight;
                document.getElementsByClassName('k-line-chart-container')[0].style.height = (h - 90) + "px";


                //console.log(h);
                //console.log(this.klineTime);
                this.initChart();
            },
            klineTypeChange(msg) {

                this.$store.commit('setKLineType', msg);
                this.klineType = msg;
                this.klineTimeChange(this.klineTime);
            },
            distoryTimer() {
                this.timeOutKline = 0;
                clearTimeout(this.timeoutObj);
            },

            setStylesOptions(theme) {
                const textColorDark = baseConfig.textColorDark;
                const gridColorDark = baseConfig.gridColorDark;
                const axisLineColorDark = baseConfig.axisLineColorDark;
                const crossTextBackgroundColorDark = baseConfig.crossTextBackgroundColorDark;

                const textColorLight = baseConfig.textColorLight;
                const gridColorLight = baseConfig.gridColorLight;
                const axisLineColorLight = baseConfig.axisLineColorLight;
                const crossTextBackgroundColorLight = baseConfig.crossTextBackgroundColorLight;

                const textColor = theme === 'dark' ? textColorDark : textColorLight
                const gridColor = theme === 'dark' ? gridColorDark : gridColorLight
                const axisLineColor = theme === 'dark' ? axisLineColorDark : axisLineColorLight
                const crossLineColor = theme === 'dark' ? axisLineColorDark : axisLineColorLight
                const crossTextBackgroundColor = theme === 'dark' ? crossTextBackgroundColorDark : crossTextBackgroundColorLight

                if (theme === 'dark') {
                    document.getElementsByClassName('k-line-chart-container')[0].style.backgroundColor = baseConfig.backgroundColorDark;
                }

                return {
                    grid: {
                        horizontal: {
                            color: gridColor
                        },
                        vertical: {
                            color: gridColor
                        }
                    },
                    candle: {
                        type: this.klineType,
                        priceMark: {
                            high: {
                                color: textColor
                            },
                            low: {
                                color: textColor
                            }
                        },
                        tooltip: {
                            text: {
                                color: textColor
                            },
                            labels: [this.$t('KLineChart.time'), this.$t('KLineChart.open'), this.$t('KLineChart.close'), this.$t('KLineChart.high'), this.$t('KLineChart.low'), this.$t('KLineChart.volume')]
                        },
                    },
                    technicalIndicator: {
                        tooltip: {
                            text: {
                                color: textColor
                            }
                        }
                    },
                    xAxis: {
                        axisLine: {
                            color: axisLineColor
                        },
                        tickLine: {
                            color: axisLineColor
                        },
                        tickText: {
                            color: textColor
                        }
                    },
                    yAxis: {
                        axisLine: {
                            color: axisLineColor
                        },
                        tickLine: {
                            color: axisLineColor
                        },
                        tickText: {
                            color: textColor
                        }
                    },
                    separator: {
                        color: axisLineColor
                    },
                    crosshair: {
                        horizontal: {
                            line: {
                                color: crossLineColor
                            },
                            text: {
                                backgroundColor: crossTextBackgroundColor
                            }
                        },
                        vertical: {
                            line: {
                                color: crossLineColor
                            },
                            text: {
                                backgroundColor: crossTextBackgroundColor
                            }
                        }
                    }
                }
            },
            toTrade() {
                this.$router.push({
                    path: '/neworder',
                    query: {
                        symbol: this.symbol,
                        orderType: 0,
                        digits: this.digits,
                        orderType: 0,
                        cmd: 1
                    }
                });

            },
            chooseSymbol() {
                this.$router.push({
                    path: "/symbols",
                    query: {
                        symbol: this.symbol,
                        path: 'Chart'
                    },
                });
            },
            lastPrice() {
                GetLastPrice().then((res) => {
                    res.msg.AllSymbol.forEach((x) => {
                        if (x.symbol == this.symbol) {
                            this.priceAsk = forPrice(x.ask, x.digits);
                            this.priceBid = forPrice(x.bid, x.digits);
                        }
                    });
                });
            },
            requestWs() {
                closeWebsocket()
                var clientid = this.userinfo.clientid;
                sendWebsocket(clientid, this.wsMessage, this.wsError)
            },
            wsMessage(peiceData) {
                peiceData.forEach(x => {
                    if (this.symbol == x.symbol) {
                        this.changeColor(x);
                        this.priceAsk = forPrice(x.ask, x.digits);
                        this.priceBid = forPrice(x.bid, x.digits);
                    }
                })
            },
            wsError() {
                console.log("wsError");
            },
            changeColor(x) {
                if ( x.ask > this.priceAsk) {
                    this.colorAsk = 'red';
                } else {
                    this.colorAsk = '#13b5b1';
                }
                if ( x.bid > this.priceBid) {
                    this.colorBid = 'red';
                } else {
                    this.colorBid = '#13b5b1';
                }
            },
            openOrder(cmd) {
                if (this.openOrderDisabled) {
                    return;
                }
                this.openOrderDisabled = true;
                let param = {
                    volume: this.volume,
                    sl: 0,
                    tp: 0,
                    cmd: cmd,
                    symbol: this.symbol,
                    comment: "",
                };
                NewOrder(param).then((res) => {
                    this.openOrderDisabled = false;
                    if (res.code == 200) {
                        Notify({ type: 'success', message: this.$t(res.msg) });
                    } else {
                        Notify({ type: 'danger', message: this.$t(res.msg) });
                    }
                });
            },
        },
        destroyed: function () {
            dispose('update-k-line');
        }
    };
</script>
<style>
    .chartKline .k-line-chart-title {
        padding: 0;
        font-size: 0.12rem;
    }

    .chartKline .k-line-chart-container {
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    .klineBar {
        background-color: #13b5b1;
    }
    /*.klineBar .van-nav-bar__left, .van-nav-bar__right {
        padding: 0 10px; color:#fff
    }

        .klineBar .van-nav-bar__title {
            max-width: 100%;
             font-size: 14px;
        }*/

    .chartKline .showTrade {        height: 35px;
    }
    .volume .van-stepper__input {
        background-color: transparent;
        height: 33px !important;
    }
    .volume .van-stepper__minus, .volume .van-stepper__plus{
        background-color:transparent
    }
    
</style>


<style scoped>
    .chartKline {
        margin-bottom: 5px;
        margin-top: 0px;
        background-color: #fff;
    }
    .sell, .buy {
        padding: 2px 0 2px 2px;
        color: #fff;
    }
    .sell, .buy, .volume {
        height: 35px;
    }

        .sell .price, .buy .price {
            font-size: 18px;
            display: block;
            text-align: center;
        }

    .volume {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

        
</style>