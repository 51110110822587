<template>
    <div class="klinetime">
        <van-nav-bar>
            <div slot="left" @click="show = true" style="color:#fff">{{klineTime}}</div>
        </van-nav-bar>
        <van-popup v-model="show"
                   position="left"
                   :style="{ height: '100%' }">
            <div class="lang">
                <div v-for="(key,index) in typeActions" :key="index">
                    <div @click="setKlineTime(key)">{{key.text}}</div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>

    export default {
        name: "KLineTimeBar",
        data() {
            return {
                show: false,
                klineTime: this.$store.state.klineTime,
                typeActions: [
                    { id: "M1", text: "M1" },
                    { id: "M5", text: "M5" },
                    { id: "M15", text: "M15" },
                    { id: "M30", text: "M30" },
                    { id: "H1", text: "H1" },
                    { id: "H4", text: "H4" },
                    { id: "D1", text: "D1" },
                    { id: "W1", text: "W1" }
                ]
            }
        },
        methods: {
            setKlineTime(ts) {
                this.$emit('listenKLineTime', ts.id);
                this.klineTime = ts.id;
                this.show = false;

            }
        }
    }
</script>

<style scoped>

    .lang {
        margin-top: 20px;
    }

        .lang > div {
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 15px;
            padding-bottom: 5px;
        }

    .other18n {
    }
</style>
